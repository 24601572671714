@import "../_config/index";

.footer {
  padding-top: 40px;

  @include breakpoint("sm") {
    padding-top: 60px;
  }

  @include breakpoint("lg") {
    padding-top: 130px;
  }

  &-top,
  &-bottom {
    & > .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint("sm") {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
      }

      @include breakpoint("md") {
        display: flex;
        flex-direction: row;
        gap: 0px;
      }
    }
  }

  &-top {
    margin-bottom: 30px;

    @include breakpoint("sm") {
      margin-bottom: 40px;
    }

    @include breakpoint("lg") {
      margin-bottom: 60px;
    }
  }

  &-bottom {
    border-top: solid 1px #969696;

    & > .wrapper {
      height: 60px;
      align-items: center;
    }

    &-center {
      font-family: text-family(font-soins-sans);
      font-size: 12px;
      font-weight: text-weight(light);
      color: #424242;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-right {
      a {
        display: block;
        text-decoration: none !important;
      }
    }
  }

  .footer-title {
    display: block;
    font-family: text-family(font-soins-sans);
    font-size: 22px;
    font-weight: text-weight(bold);
    color: #424242;
    cursor: pointer;

    @include breakpoint("sm") {
      cursor: default;
    }
  }
}

.footer-column {
  margin-bottom: 40px;

  @include breakpoint("sm") {
    margin-bottom: 0px;
  }

  &.footer-logo {
    display: none;

    @include breakpoint("sm") {
      display: flex;
      flex-direction: column;
    }

    .logo {
      justify-content: flex-start;
    }
  }
}

.expandable-menu {
  .footer-title {
    display: flex;
    align-items: center;

    &:before {
      width: 10px;
      height: 14px;
      margin-right: 10px;
      top: 0px;
      left: 4px;
      transform: rotate(90deg);
      display: block;
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f054";

      @include breakpoint("sm") {
        display: none;
      }
    }
  }

  .footer-links {
    display: none;

    @include breakpoint("sm") {
      display: block !important;
    }
  }
}

.footer-row {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin-top: 20px;

  li {
    a {
      display: block;
      font-family: text-family(font-soins-sans-roman);
      font-size: 16px;
      font-weight: text-weight(light);
      color: #424242;
    }
  }
}

.footer-social-networks {
  max-width: 204px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  li {
    width: 36px;
    height: 36px;
    background: #fff;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      position: relative;
    }
  }
}

.footer-cards {
  width: 170px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;

  li {
    width: 50px;
    height: 32px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    display: block;

    &.card-american {
      background-image: url(image("cards/american.svg"));
    }

    &.card-aura {
      background-image: url(image("cards/aura.svg"));
    }

    &.card-boleto {
      background-image: url(image("cards/boleto.svg"));
    }

    &.card-diners {
      background-image: url(image("cards/diners.svg"));
    }

    &.card-elo {
      background-image: url(image("cards/elo.svg"));
    }

    &.card-hiper {
      background-image: url(image("cards/hiper.svg"));
    }

    &.card-master {
      background-image: url(image("cards/master.svg"));
    }

    &.card-visa {
      background-image: url(image("cards/visa.svg"));
    }
  }
}

.footer-partners {
  width: 90px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  li {
    width: 100%;
    height: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: cover;
    display: block;

    &.partner-aws {
      background-image: url(image("partners/aws.png"));
    }

    &.partner-lets {
      background-image: url(image("partners/lets.png"));
    }
  }
}
