@import "../_config/index";

.stores-list {
  margin-bottom: 80px;

  &-content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;

    @include breakpoint("xs") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint("sm") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint("md") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include breakpoint("lg") {
      grid-template-columns: repeat(5, 1fr);
    }

    .view-row {
      @include aspect-ratio(260px, 180px);
      padding: 20px;
      border-radius: 4px;
      border: solid 1px #000;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        display: block;
        max-width: 100%;
      }

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
