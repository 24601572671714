@import "../_config/index";

.header {
  display: flex;
  flex-direction: column;

  .top-strip {
    order: 1;
  }

  .header-content {
    order: 2;
  }

  .wrapper {
    position: relative;
  }
}
