@import "../_config/index";

.gallery {
  display: none;

  @include breakpoint("sm") {
    width: calc(#{$gallerySize} - 250px);
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include breakpoint("md") {
    width: calc(#{$gallerySize} - 100px);
    height: 650px;
  }

  @include breakpoint("lg") {
    width: $gallerySize;
    height: 776px;
  }

  .gallery-thumbnails {
    height: 100%;

    .gallery-thumbnails-content {
      width: $galleryThumbsSize;
      height: 100%;
      overflow: hidden;
      display: grid;
      gap: 16px;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);

      @include breakpoint("sm") {
        width: calc(#{$galleryThumbsSize} - 40px);
      }

      @include breakpoint("md") {
        width: calc(#{$galleryThumbsSize} - 10px);
      }

      @include breakpoint("lg") {
        width: $galleryThumbsSize;
      }

      & > .gallery-thumbnail-item {
        max-width: $galleryThumbsSize;
        overflow: hidden;
        height: 180px;

        .product-thumbnail-item-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          display: block;
          cursor: zoom-in;
        }
      }
    }
  }

  .product-photo-main {
    @include breakpoint("sm") {
      width: calc(
        #{$gallerySize} - 16px - (#{$galleryThumbsSize} - 40px) - 250px
      );
    }

    @include breakpoint("md") {
      width: calc(
        #{$gallerySize} - 16px - (#{$galleryThumbsSize} - 10px) - 100px
      );
    }

    @include breakpoint("lg") {
      width: calc(#{$gallerySize} - 16px - #{$galleryThumbsSize});
      .product-image-main {
        margin-top: 0;
      }
    }
  }

  &-mobile {
    width: 100%;
    margin-bottom: 60px;
    display: block;
    background: #fff;
    border-radius: 6px;
    position: relative;

    @include breakpoint("sm") {
      display: none;
    }

    .active-mouse-hover {
      content: "";
      background: transparent;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      position: absolute;
      z-index: 9;
    }

    li {
      overflow: hidden;
      &,
      figure,
      figure a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 460px;
        }
      }
    }

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
      overflow: hidden;
    }

    .owl-stage-outer {
      margin-bottom: 30px;
    }

    .owl-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .owl-nav {
      left: -12px;
      right: -12px;

      button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color(orangePrimary) !important;

        span {
          display: inline-block;
          line-height: 20px;
          font-size: 24px;
          color: #fff;
        }
      }
    }
  }
}

.product-video {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: none;
  position: absolute;
  z-index: 9;

  &-visible {
    display: block;
  }

  &-mobile {
    width: 100%;
    height: 460px;
    display: block;
    position: relative;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.gallery-video {
  position: relative;

  a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  span {
    display: flex;
    background-color: rgba(color(blackColor), 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: transition(default);
    position: absolute;
    cursor: pointer;
    z-index: 9;

    &:hover {
      background-color: rgba(color(blackColor), 0.2);
    }

    i {
      margin: 0;
      padding: 0;
      font-size: 40px;
      color: #f00;
      position: relative;

      &:before {
        position: relative;
        z-index: 2;
      }

      &:after {
        display: block;
        background: #fff;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        z-index: 1;
      }
    }
  }
}
