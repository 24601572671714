@import "../_config/index";

.number-select {
  width: 50%;
  display: flex;
  flex-direction: column;

  label {
    margin: 0 0 10px;
    display: block;
    text-align: left;
    font-family: text-family(font-soins-sans);
    font-size: 16px;
    font-weight: text-weight(bold);
    color: #424242;
  }

  .input-number-pieces {
    width: 100%;
    height: 42px;
    padding: 0;
    position: relative;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    border: solid 1px #636262;
    border-radius: 4px;
    font-family: text-family(font-soins-sans-roman);
    font-size: 13px;
    font-weight: text-weight(regular);
    color: #636262;
    appearance: textfield;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    width: 40px;
    padding: 4px 0;
    margin: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    right: 0px;
    cursor: pointer;
    transform: translateY(-50%);
    transition: transition(default);
    position: absolute;

    i {
      font-size: 12px;
      color: #636262;
    }

    &.button-add-pieces {
      top: 15px;
    }

    &.button-remove-pieces {
      bottom: -7px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
