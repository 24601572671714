@import "../_config/index";

@mixin breadcrumb-arrow {
  display: block;
  content: "\f105" !important;
  font-family: FontAwesome;
  font-weight: bold;
  font-size: 18px;
  color: color(grayPrimary);
}

.page-nav {
  margin-bottom: 40px;
  justify-content: space-between;
  flex-direction: column;

  &-left {
    margin-top: 58px;
  }

  &,
  &-left,
  &-right {
    display: flex;
    align-items: initial;

    @include breakpoint("sm") {
      align-items: center;
    }
  }

  @include breakpoint("sm") {
    flex-direction: row;
  }

  &-left {
    width: 100%;
    margin-bottom: 20px;
    justify-content: left;

    @include breakpoint("sm") {
      width: calc(70% - 10px);
      margin-bottom: 0px;
    }
  }

  &-right {
    width: 100%;
    justify-content: space-between;

    @include breakpoint("sm") {
      width: calc(50% - 10px);
      justify-content: right;
    }
  }
}

.breadcrumb {
  background: transparent;
  margin: 0;
  border-radius: 0;

  &,
  & > div,
  & > ul,
  & > ul > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 7px;
    text-transform: none;
    @include breakpoint("md") {
      column-gap: 17px;
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  & > div {
    display: none;
    @include breakpoint("md") {
      display: block;
    }
    & > a {
      font-family: text-family(font-soins-sans);
      font-weight: text-weight(bold);
      font-size: 20px;
      color: color(grayPrimary);
    }
  }

  & > ul {
    & > li {
      display: flex;
      align-items: center;
      font-family: FontAwesome;
      padding: 0;
      
      &:first-child {
        margin-right: 8px;
        
        a {
          font-weight: bold;
        }
      }

      &:nth-child(n+3){
        margin-left: 14px;
      }

      @include breakpoint("md") {
        margin: 0;
        &:first-child {
          margin-right: 0;
          a {
            font-weight: text-weight(regular);
          }
        }
        &:nth-child(n+3){
          margin-left: 0;
        }
  
      }

      &:before {
        @include breadcrumb-arrow;
      }

      &,
      a {
        font-family: text-family(font-soins-sans);
        font-size: 20px;
        font-weight: text-weight(regular);
        color: #424242;

        strong {
          margin-left: 5px;
          font-weight: text-weight(bold);
        }
      }

      a {
        display: block;
      }
    }
  }
}

.change-column {
  & > a {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0 3px;
    cursor: pointer;

    & > span {
      width: 6px;
      height: 16px;
      display: block;
      background: #dbdbdb;
      margin: 0 2px;
      transition: transition(fast);
    }

    &:hover {
      & > span {
        background: lighten(color(orangePrimary), 20%);
      }
    }

    &#column-item-active {
      & > span {
        background: color(orangePrimary);
      }
    }
  }

  &-desktop {
    display: none;

    @include breakpoint("sm") {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }

  &-mobile {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include breakpoint("sm") {
      display: none;
    }

    .change-column-item {
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &.square-block {
        position: relative;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    span {
      background: pink;
      display: block;
    }

    .change-column-span__list {
      width: 100%;
      height: 4px;
    }

    .change-column-span__big {
      width: 100%;
      height: 13px;
    }

    .change-column-span__small {
      width: 100%;
      height: 3px;
    }

    .change-column-span__square {
      width: 8px;
      height: 8px;
      margin: 0;
      position: absolute;

      &.one-square {
        top: 0;
        left: 0;
      }

      &.two-square {
        top: 0;
        right: 0;
      }

      &.three-square {
        bottom: 0;
        left: 0;
      }

      &.four-square {
        bottom: 0;
        right: 0;
      }
    }
  }
}

.sort-by__select {
  & > .sort-by__options {
    padding: 0 10px;

    &,
    &:hover {
      min-width: 180px;
      display: block;
      border-radius: 6px;
      border: solid 1px color(grayPrimary03);
      font-family: text-family(font-gotham-pro);
      font-size: text-size(xxs);
      font-weight: text-weight(light);
      color: color(grayPrimary03);
    }
  }
}
