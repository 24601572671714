@import "../_config/index";

.owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
  }

  .owl-stage {
    .owl-item {
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      min-height: 1px;
      float: left;
      -webkit-touch-callout: none;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      position: relative;
    }
  }
}

.owl-nav {
  top: 50%;
  left: -16px;
  right: -16px;
  transform: translateY(-50%);
  position: absolute;
  z-index: 9;

  &.disabled {
    display: none;
  }

  button {
    width: 36px;
    height: 72px;
    background-color: color(orangePrimary);
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    position: absolute;

    span {
      display: none;
    }

    &.disabled {
      display: none;
    }

    &.owl-prev {
      left: 0;
      background: color(secondaryColor) url(image("arrow-left-white.png"))
        center center no-repeat !important;
    }

    &.owl-next {
      right: 0;
      background: color(secondaryColor) url(image("arrow-right-white.png"))
        center center no-repeat !important;
    }
  }
}

.owl-dots {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;

  .owl-dot {
    border: none;
    background: none;

    &,
    & span {
      transition: transition(default);
    }

    span {
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      margin: 0 5px;
      background: color(secondaryColor);
    }

    &.active {
      span {
        background: color(primaryColor);
        box-shadow: 0 0 0 3px color(whiteColor), 0 0 0 4px color(primaryColor);
      }
    }
  }

  &.disabled {
    display: none;
  }
}
