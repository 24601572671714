@import "../_config/index";

.menu {
  &-mobile {
    display: block;

    @include breakpoint("sm") {
      display: none;
    }
  }

  & > ul {
    display: none;

    @include breakpoint("sm") {
      display: flex;
    }

    & > li {
      display: flex;

      & > a {
        height: 85px;
        padding: 0 20px;
        align-items: center;
        justify-content: center;
        font-family: text-family(font-soins-sans);
        font-size: text-size(xxs);
        font-weight: text-weight(bold);
        color: color(grayPrimary);
        overflow: hidden;
        position: relative;

        &,
        span {
          display: flex;
          text-transform: uppercase;
          text-decoration: none !important;
          position: relative;
          z-index: 2;
        }

        &:after {
          height: 0%;
          top: 0;
          left: 0;
          right: 0;
          display: block;
          background: color(iceColor);
          content: "";
          position: absolute;
          transition: transition(default);
          z-index: 1;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        display: none;

        @include breakpoint("md") {
          display: block;
        }
      }

      &:nth-child(5) {
        display: none;

        @include breakpoint("lg") {
          display: block;
        }
      }

      &:hover {
        & > a {
          &:after {
            height: 100%;
          }
        }

        & .menu-first-sub {
          display: block;
          animation: submenu-hover ease-in-out 0.2s both;
          background: color(iceColor);  
        }
      }

      & .menu-first-sub {
        height: 58px;
        top: 100%;
        left: 0;
        right: 0;
        transform: translateY(10px);
        display: none;
        position: absolute;
        transition: transition(default);
        opacity: 0;
        z-index: 11;

        & > ul {
          display: flex;

          & > li {
            display: flex;
            position: relative;
            z-index: 2;

            & > a {
              padding: 0 15px;
              overflow: hidden;

              &,
              span {
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none !important;
                font-family: text-family(font-soins-sans-roman);
                font-size: text-size(xxs);
                font-weight: textWeight(regular);
                color: color(grayPrimary);
                position: relative;
                z-index: 2;
              }

              &:before,
              &:after {
                right: 0;
                left: 0;
                display: block;
                position: absolute;
                content: "";
                transition: transition(default);
                z-index: 1;
              }

              &:before {
                height: 0px;
                background: color(whiteColor);
                top: 0;
              }

              &:after {
                height: 0px;
                background: color(orangePrimary);
                bottom: 0;
              }
            }

            &:hover {
              & > a {
                &:before {
                  height: 100%;
                }

                &:after {
                  height: 4px;
                }
              }

              & > .menu-drop-sub {
                display: block;
                animation: submenu-dropdown ease-in-out 0.2s both;
              }
            }
          }
        }
      }

      & .menu-drop-sub {
        display: none;
        top: 100%;
        left: 0;
        padding: 20px;
        background-color: color(whiteColor);
        position: absolute;
        opacity: 0;
        z-index: 3;

        ul {
          line-height: 24px;

          li {
            a {
              display: block;
              white-space: nowrap;
              font-family: text-family(font-soins-sans);
              font-size: text-size(xxs);
              font-weight: textWeight(light);
              color: color(grayPrimary);

              strong {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes submenu-hover {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes submenu-dropdown {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
