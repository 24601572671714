@import "../_config/index";

.slides-home {
  margin-bottom: 44px;
  @include breakpoint("sm") {
    margin-bottom: 56px;
  }
  @include breakpoint("md") {
    margin-bottom: 23px;
  }

  .slider-home {
    position: relative;

    &--desktop {
      display: none;

      @include breakpoint("sm") {
        display: block;
      }
    }

    &--mobile {
      display: block;

      @include breakpoint("sm") {
        display: none;
      }
    }
  }

  .carousel-indicators {
    width: auto;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 9;

    li {
      width: 10px;
      height: 10px;
      margin: 0 12px;
      background: color(whiteColor);
      border: none;
      position: relative;

      &:after {
        width: 0;
        height: 0;
        border: none;
        border-radius: 50%;
        content: "";
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        transition: transition(fast);
      }

      &.active {
        background: color(orangePrimary);

        &:after {
          width: 16px;
          height: 16px;
          border: solid 1px color(orangePrimary);
        }
      }
    }
  }
}

.banner-full-home {
  img {
    max-width: unset;
    min-width: 100%;
  }

  .banner-full {
    justify-content: center;
    margin-bottom: 60px;
    overflow: hidden;

    &--desktop {
      display: none;

      @include breakpoint("sm") {
        display: flex;
      }
    }

    &--mobile {
      display: flex;

      @include breakpoint("sm") {
        display: none;
      }
    }
  }
}

.banner-area-2, .banner-area-5, .banner-area-6, .banner-area-7 {
  > div {
    gap: 15px;
    margin-bottom: 60px;
    justify-content: center;
  }

  &--desktop {
    display: none;

    @include breakpoint("sm") {
      display: flex;
    }
  }

  &--mobile {
    display: flex;

    @include breakpoint("sm") {
      display: none;
    }
  }
}