@import "../_config/index";

.message-newsletter {
  padding: 20px;
  top: 20px;
  left: 20px;
  right: 20px;
  background: color(notificationInfo);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 999;

  @include breakpoint("sm") {
    min-width: 700px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  &:after {
    width: 0%;
    height: 4px;
    display: block;
    background: rgba(255, 255, 255, 0.4);
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    transition: all 6s linear;
  }

  &.start-loading {
    &:after {
      animation: progressBar 6s linear;
    }
  }

  &.success {
    background: color(notificationSuccess);
  }

  &.error {
    background: color(notificationError);
  }

  &.warming {
    background: color(notificationWarming);
  }

  & > i {
    font-size: 22px;
    font-weight: 100;
    color: color(whiteColor);
  }

  p {
    margin: 0;
    padding: 0;
    font-family: text-family(font-soins-sans);
    font-size: text-size(xxs);
    font-weight: text-weight(regular);
    color: color(whiteColor);
  }

  a {
    width: 30px;
    height: 30px;
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none !important;
    cursor: pointer;

    i {
      font-size: 20px;
      font-weight: 100;
      color: color(whiteColor);
    }
  }
}

.newsletter {
  background: #ececec;
  padding: 34px 0 30px;
}

.form-newsletter {
  margin-bottom: 20px;
  position: relative;
  max-width: 1211px;

  h3,
  h4 {
    text-align: center;
    font-size: 22px;

    @include breakpoint("xs") {
      font-size: 28px;
    }

    @include breakpoint("sm") {
      text-align: left;
      font-size: 32px;
    }
  }

  h3 {
    margin-bottom: 10px;
    font-family: text-family(font-soins-sans-roman);
    font-weight: text-weight(regular);
    color: #000;

    @include breakpoint("sm") {
      margin-bottom: 0;
    }
  }

  h4 {
    font-family: text-family(font-soins-sans);
    font-weight: text-weight(bold);
    color: #f30;
  }

  fieldset {
    padding: 0;
    margin: 32px 0 20px;
    display: flex;
    border: none;
    flex-direction: column;

    @include breakpoint("sm") {
      flex-direction: row;
    }

    input {
      width: 100%;
      height: 42px;
      padding: 0 20px;
      margin-bottom: 12px;
      margin-right: 16px;
      border: solid 1px #dbdbdb;
      border-radius: 6px;
      background: #fff;
      font-family: text-family(font-soins-sans-roman);
      font-size: 16px;
      font-weight: text-weight(normal);
      color: #7e7e7e;
      &::placeholder{
        color: #7e7e7e;
      }

      @include breakpoint("sm") {
        width: 270px;
        margin-bottom: 0;
      }
    }

    button {
      width: 100%;
      height: 42px;
      border: none;
      background: #f30;
      border-radius: 6px;
      text-transform: uppercase;
      cursor: pointer;
      transition: transition(fast);
      font-family: text-family(font-soins-sans);
      font-size: 14px;
      font-weight: text-weight(bold);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint("sm") {
        width: 200px;
      }

      span {
        width: 16px;
        height: 16px;
        display: block;
        overflow: hidden;
        margin-left: 10px;
      }

      &[disabled],
      &[disabled="disabled"] {
        &,
        &:hover {
          cursor: not-allowed;
          background: color(grayPrimary03);
          color: lighten(color(grayPrimary03), 20%);
        }
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &,
    input,
    span {
      margin: 0;
      padding: 0;
    }

    input {
      width: 12px;
      height: 12px;
      border: none;
      margin-right: 10px;
      margin-left: 0px !important;
      display: block;
      appearance: none;
      position: relative !important;

      &[disabled],
      &[disabled="disabled"] {
        &,
        &:hover {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }

      &:before,
      &:after {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transition(fast);
      }

      &:before {
        border: solid 2px #f30;
        border-radius: 3px;
        background: #ececec;
        content: "";
      }

      &:after {
        width: 0;
        height: 0;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f00c";
        color: #f30;
        z-index: 2;
        opacity: 0;
      }

      &:checked {
        &:after {
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }
    }

    span {
      display: block;
      font-family: text-family(font-soins-sans-roman);
      font-size: 12px;
      line-height: 14px;
      font-weight: text-weight(light);
      color: #52606c;
      padding-top: 4px;
    }
  }
}

.newsletter-info {
  padding: 27px 20px;
  background: #636262;
  line-height: 24px;
  font-family: text-family(font-soins-sans-roman);
  font-size: 20px;
  font-weight: text-weight(regular);
  color: #fff;
  position: relative;
  margin: 20px -12px -50px;

  @include breakpoint("sm") {
    margin: 30px -24px -50px;
  }

  @include breakpoint("lg") {
    width: 365px;
    margin: 0;
    bottom: -100px;
    right: 0;
    position: absolute;
    z-index: 2;
  }
}

.spinner {
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        rgb(255, 255, 255) 94%,
        rgba(0, 0, 0, 0)
      )
      center top / 3px 3px no-repeat,
    conic-gradient(rgba(0, 0, 0, 0) 30%, rgb(255, 255, 255));
  -webkit-mask: radial-gradient(
    farthest-side,
    rgba(0, 0, 0, 0) calc(100% - 3px),
    rgb(0, 0, 0) 0px
  );
  animation: 0.8s linear 0s infinite normal none running spinner;
}

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes progressBar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
