@import "../_config/index";

.header-content {
  position: relative;

  & > .wrapper {
    // min-height: $headerheight;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  &:after {
    top: 100%;
    left: 0;
    right: 0;
    display: flex;
    height: 0;
    background: color(iceColor);
    content: "";
    transition: transition(default);
    position: absolute;
    z-index: 10;
  }

  &.menu-open {
    &:after {
      height: 58px;
    }
  }

  .logo {
    order: 2;

    @include breakpoint("sm") {
      order: 1;
    }
  }

  .menu {
    order: 1;

    @include breakpoint("sm") {
      order: 2;
    }
  }

  .form-search {
    order: 4;

    @include breakpoint("md") {
      order: 3;
    }
  }

  .user-menu {
    order: 3;

    @include breakpoint("md") {
      order: 4;
    }
  }
}
