@import "../_config/index";

.product-share {
  display: flex;
  margin-left: 6px;
  position: relative;

  &-button {
    display: block;
    background: none;
    border: none;
    transform: translateY(2px);
    text-decoration: none !important;

    img {
      display: block;
    }
  }

  &-list {
    right: 0;
    bottom: 100%;
    position: absolute;
    z-index: 9;
    display: none;

    &:before,
    &:after {
      background-color: transparent;
      display: block;
      content: "";
    }

    &:before {
      width: 0;
      height: 0;
      border-top: solid 15px color(whiteColor);
      border-right: solid 8px transparent;
      border-bottom: solid 0px transparent;
      border-left: solid 8px transparent;
      bottom: 0px;
      right: 10px;
      position: absolute;
    }

    &:after {
      width: 100%;
      height: 15px;
    }

    & > ul {
      display: flex;
      column-gap: 15px;
      padding: 15px 20px;
      background: color(whiteColor);
      border-radius: 4px;
      box-shadow: 0px 6px 8px rgba(color(blackColor), 0.2);

      li {
        a {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none !important;

          i {
            font-size: 20px;
            color: color(grayPrimary02);
          }

          &:hover {
            transform: translateY(-5px);
          }
        }

        &.product-share-list-facebook > a {
          background: color(grayPrimary02);
          border-radius: 50%;

          i {
            color: color(whiteColor);
            font-size: 16px;
          }
        }
      }
    }
  }

  &:hover {
    .product-share-list {
      display: block;
    }
  }
}
