@import "../_config/index";

.product-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px #ececec;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  background: #fff;
  transition: transition(fast);
  cursor: pointer;
  position: relative;

  &-image {
    @include aspect-ratio(330px, 420px);
    position: relative;
    height: 440px;

    a {
      top: 0;
      left: 0;
      position: absolute;

      &,
      & > img {
        width: 100%;
        height: 100%;
        display: block;
        transition: transition(fast);
      }

      img {
        object-fit: cover;
        object-position: top center;
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding: 20px 15px;

    h3 {
      width: 100%;
      margin-bottom: 20px;
      text-align: left;

      &,
      & > a {
        line-height: 18px;
        font-size: 14px;
        display: block;
        font-family: text-family(font-soins-sans-roman);
        font-weight: text-weight(regular);
        color: #424242;

        &:hover {
          text-decoration: none;
        }

        @include breakpoint("sm") {
          line-height: 20px;
          font-size: 16px;
        }

        @include breakpoint("md") {
          line-height: 24px;
          font-size: 20px;
        }

        @include breakpoint("lg") {
          line-height: 28px;
          font-size: 24px;
        }
      }
    }

    & > .product-box-prices {
      width: 100%;
      display: flex;

      del,
      strong {
        display: block;
        font-size: 12px;

        @include breakpoint("sm") {
          font-size: 14px;
        }

        @include breakpoint("md") {
          font-size: 16px;
        }
      }

      del {
        font-family: text-family(font-soins-sans-roman);
        font-weight: text-weight(regular);
        color: #969696;
      }

      strong {
        font-family: text-family(font-soins-sans);
        font-weight: text-weight(bold);
        color: #424242;
      }
    }
  }
}
