@import "../_config/index";

* {
  outline: none !important;
}

html,
body {
  transition: transition(fast);
}

html {
  left: 0;
  position: relative;

  &.menu-mobile-html {
    height: 100%;
    overflow: hidden;

    .menu-out-click {
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-color: transparent;
      position: fixed;
      z-index: 15;
    }
  }
}

body {
  background: color(whiteColor);
}

#{headings(1,6)},
a,
p {
  margin: 0;
  padding: 0;
  font-family: text-family(font-soins-sans-roman);
  font-weight: text-weight(normal);
}

figure {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  transition: transition(default);
  color: color(grayPrimary);

  &:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: text-size(xxl);
}

h2 {
  font-size: text-size(xl);
}

h3 {
  font-size: text-size(l);
}

h4 {
  font-size: text-size(m);
}

h5 {
  font-size: text-size(s);
}

h6 {
  font-size: text-size(xs);
}

p {
  font-size: text-size(xxs);
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hidden-scroll-x {
  width: 100%;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
}

.wrapper {
  padding: 0 12px;

  @include breakpoint("sm") {
    padding: 0 24px;
  }

  @include breakpoint("lg") {
    max-width: 1280px;
    padding: 0;
    margin: 0 auto;
  }
}

.hidden {
  display: none;
}

.container {
  @include breakpoint("lg") {
    width: calc(1366px + 30px);
  }
}
