@import "../_config/index";

.product-content {
  width: 100%;
  padding-top: 44px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint("sm") {
    flex-direction: row;
  }
}

.product-info-content {
  width: 100%;

  @include breakpoint("sm") {
    width: calc(100% - #{$gallerySize} - 20px + 250px);
  }

  @include breakpoint("md") {
    width: calc(100% - #{$gallerySize} - 30px + 100px);
  }

  @include breakpoint("lg") {
    width: calc(100% - #{$gallerySize} - 60px);
  }
}

.product-header {
  margin-bottom: 30px;

  .product-store-supplier {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;

    span,
    a {
      font-size: 16px;
      color: #636262;
    }

    span {
      margin-right: 5px;
      font-family: text-family(font-soins-sans);
      font-weight: text-weight(regular);
    }

    a {
      text-decoration: none;
      font-family: text-family(font-soins-sans-roman);
      font-weight: text-weight(bold);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    h2 {
      margin: 0;
      padding: 0;
      font-family: text-family(font-soins-sans);
      font-weight: text-weight(bold);
      font-size: 32px;
    }
  }

  .product-sku {
    font-family: text-family(font-soins-sans);
    font-weight: text-weight(regular);
    font-size: 14px;
  }
}

.product-shipping-panel {
  width: 100%;

  .calculate-shipping {
    width: 100%;

    h4 {
      display: block;
      margin-bottom: 8px;
      font-family: text-family(font-soins-sans);
      font-weight: text-weight(bold);
      font-size: 16px;
      color: #464646;
    }

    #shipping-options-form-panel {
      width: 100%;

      .calculate-shipping-content {
        width: 100%;

        .calculate-shipping-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .input-cep,
          button {
            height: 42px;
            border-radius: 6px;
            font-size: 14px;
          }

          .input-cep {
            width: 210px;
            margin-right: 10px;
            background: #fff;
            display: block;
            border: solid 1px #636262;
            padding: 0 20px;
            text-align: center;
            font-family: text-family(font-soins-sans-roman);
            font-weight: text-weight(regular);
            color: #636262;
          }

          button {
            width: 42px;
            border: none;
            font-weight: text-weight(bold);
            background: #f30;
            color: #fff;
          }
        }
      }
    }
  }
}

.product-prices {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;

  .product-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .product-type {
      display: flex;
      flex-direction: column;

      .product-prices-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 5px;
      }

      .product-box-business-model {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: text-family(font-soins-sans-roman);
        font-weight: text-weight(bold);
        font-size: 12px;
        color: color(orangePrimary);
      }

      .old-price {
        margin-right: 14px;
        font-family: text-family(font-soins-sans-roman);
        font-weight: text-weight(regular);
        font-size: 16px;
        color: #969696;
      }

      .current-price {
        font-family: text-family(font-soins-sans);
        font-weight: text-weight(bold);
        font-size: 22px;
        color: #424242;

        strong {
          font-family: text-family(font-soins-sans);
          font-weight: text-weight(bold);
          font-size: 16px;
          color: #424242;
        }
      }

      .product-price-installments {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .product-price-divided {
          margin-right: 10px;
          font-family: text-family(font-soins-sans-roman);
          font-weight: text-weight(regular);
          font-size: 16px;
          color: #424242;
        }
      }

      .product-price-b2b-disabled {
        width: 240px;
      }
    }
  }

  .price-divider {
    width: 1px;
    height: 75px;
    margin: 0 10px;
    background-color: color(grayPrimary05);

    @include breakpoint("sm") {
      margin: 0 20px;
    }

    @include breakpoint("md") {
      margin: 0 30px;
    }

    @include breakpoint("lg") {
      margin: 0 40px;
    }
  }
}

.button-see-parcels {
  text-transform: uppercase;
  font-family: text-family(font-soins-sans);
  font-weight: text-weight(bold);
  font-size: 16px;
  color: #424242;
}

.product-minimum-purchase {
  margin-bottom: 30px;
  font-family: text-family(font-soins-sans-roman);
  font-weight: text-weight(regular);
  font-size: 14px;
  color: #424242;

  strong {
    font-weight: text-weight(bold);
  }
}

.product-buy-methods {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .product-buy-action {
    min-width: 200px;
    height: 42px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    text-decoration: none !important;
    text-transform: uppercase;
    font-family: text-family(font-soins-sans-roman);
    font-weight: text-weight(bold);
    font-size: 14px;
    color: #fff !important;
    background: color(orangePrimary);

    i {
      margin-right: 10px;
      transform: translateY(-2px);
    }

    &.button-whatsapp {
      background: #25d366;
    }

    &[disabled="disabled"] {
      cursor: not-allowed;
      background: color(grayPrimary02);
      opacity: 0.3;
    }
  }
}

.product-description {
  margin-bottom: 50px;

  .product-description-text {
    &,
    & p {
      font-family: text-family(font-soins-sans-roman);
      font-weight: text-weight(regular);
      font-size: 20px;
      color: #424242;

      strong,
      strong {
        font-family: text-family(font-soins-sans);
        font-weight: text-weight(bold);
      }
    }
  }
}

.product-questions {
  margin-bottom: 60px;
}

.product-question-form {
  & > div {
    $buttonSize: 200px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    [type="text"] {
      width: calc(100% - 20px - #{$buttonSize});
      height: 42px;
      padding: 0 20px;
      display: block;
      border: solid 1px color(grayPrimary03);
      border-radius: 4px;
      background: color(whiteColor);
      margin-right: 20px;
      font-family: text-family(font-soins-sans-roman);
      font-size: text-size(xxs);
      font-weight: text-weight(regular);
      color: color(grayPrimary03);

      &[disabled="disabled"] {
        cursor: not-allowed;
        background-color: color(grayPrimary04);
        color: color(grayPrimary02);
      }
    }

    [type="submit"] {
      width: $buttonSize;
      height: 42px;
      display: block;
      border: solid 1px color(orangePrimary);
      border-radius: 4px;
      background: color(whiteColor);
      text-transform: uppercase;
      font-family: text-family(font-soins-sans-roman);
      font-size: text-size(xxs);
      font-weight: text-weight(medium);
      color: color(orangePrimary);
      transition: transition(fast);
      cursor: pointer;

      &:hover {
        background: color(orangePrimary);
        color: color(whiteColor);
      }

      &[disabled="disabled"] {
        &,
        &:hover {
          cursor: not-allowed;
          border: solid 1px color(grayPrimary03);
          background-color: color(grayPrimary04);
          color: color(grayPrimary03);
        }
      }
    }
  }

  & > p {
    margin: 0;
    font-family: text-family(font-soins-sans-roman);
    font-size: text-size(xxs);
    font-weight: text-weight(regular);
    color: color(grayPrimary02);

    a {
      color: color(grayPrimary03);
    }
  }
}

.product-questions-list {
  margin-top: 40px;
}
