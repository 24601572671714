@import "../_config/index";

.showcases {
  display: none;
  position: relative;

  @include breakpoint("sm") {
    display: block;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  &-item {
    position: relative;
  }

  &-mobile {
    display: block;

    .owl-dots {

      display: flex;
      overflow: hidden;
      justify-content: space-around;

      .owl-dot {
        padding: 5px 0;

        > span {
          margin: 0;
        }
      }
    }

    @include breakpoint("sm") {
      display: none;
    }
  }
}

.showcases-wrapper {
  margin-bottom: 60px;
}

.owl-dots {
  .owl-dot {
    span {
      width: 10px;
      height: 10px;
      margin: 0 12px;
      background: #ececec;
      border: none;
      position: relative;
      display: block;

      &:after {
        width: 0;
        height: 0;
        border: none;
        border-radius: 50%;
        content: "";
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        transition: transition(fast);
      }
    }

    &.active {
      span {
        background: color(orangePrimary);

        &:after {
          width: 16px;
          height: 16px;
          border: solid 1px color(orangePrimary);
        }
      }
    }
  }
}


.img-product-container {
  .hidden-image {
    display: none;
  }

  .hidden-standard {
    display: block;
  }

  &:hover {
    .hidden-standard ~ .hidden-image {
      display: block;
    }

    .hidden-standard {
      display: none;
    }
  }
}

#carousel-area-2 {
  display: none;
  margin: 23px 0;
  @include breakpoint("md") {
    display: block;
  }

}

#carousel-area-3 {
  display: none;
  margin: 23px 0 33px;
  @include breakpoint("md") {
    display: block;
  }


}

#carousel-categories {
  display: none;
  margin: 0px 0 68px;
  @include breakpoint("md") {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    &.owl-carousel {
      display: block;
    }
  }

  .carousel-category-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: text-family(font-soins-sans-roman);
    font-size: 16px;
    line-height: 16px;
    font-weight: text-weight(normal);
    color: #424242;

    &:hover {
      text-decoration: none;
    }

    img {
      width: 90px;
      height: 90px;
      border-radius: 50px;
      margin-bottom: 10px;
    }
  }

}


