@import "../_config/index";

.logo {
  &,
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
