@import "../_config/index";

.variants-components {
  max-width: 420px;
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  position: relative;

  .variants-title {
    margin: 0 0 10px;
    display: block;
    text-align: left;
    font-family: text-family(font-soins-sans);
    font-size: 16px;
    font-weight: text-weight(bold);
    color: #424242;
  }
}

.variant-sizes {
  .variant-sizes-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .variant-sizes-label {
      height: 30px;
      display: inline-flex;
      margin: 0;
      position: relative;

      input {
        display: none;
        top: 0;
        left: 0;
        position: absolute;

        &:checked {
          & + span {
            border: solid 1px color(orangePrimary);
            background: color(orangePrimary);
            color: color(whiteColor);
          }
        }
      }

      span {
        height: 100%;
        padding: 0 10px;
        display: inline-flex;
        border-radius: 4px;
        border: solid 1px color(grayPrimary02);
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: text-family(font-soins-sans);
        font-size: 14px;
        font-weight: text-weight(bold);
        color: color(grayPrimary02);
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: color(grayPrimary02);
          color: color(whiteColor);
        }
      }
    }
  }
}

.variant-colors {
  width: 50%;
  margin-right: 16px;

  .variant-colors-content {
    width: 100%;
    height: 43px;
    position: relative;

    .variant-select {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0 16px;
      margin: 0;
      float: none;
      clear: initial;
      border-radius: 4px;
      background: color(whiteColor);
      border: solid 1px color(grayPrimary02);
      font-family: text-family(default);
      font-size: 13px;
      font-weight: text-weight(light);
      color: color(grayPrimary02);
      cursor: pointer;
    }

    .fa-angle-down {
      top: 50%;
      right: 15px;
      font-size: 16px;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}
