@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}
