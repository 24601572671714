@import "../_config/index";

.user-menu {
  height: $headerheight;
  display: flex;
  align-items: center;

  a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 10px;
    }

    img {
      display: block;
      transition: transition(fast);
    }

    span {
      width: 26px;
      height: 26px;
      padding: 3px 0 0;
      border-radius: 50%;
      background: lighten(color(orangePrimary), 15%);
      display: flex;
      justify-content: center;
      text-decoration: none !important;
      font-family: text-family(font-soins-sans-roman);
      font-size: 16px;
      color: color(whiteColor);
      transition: transition(fast);
    }

    &:focus,
    &:hover {
      &,
      span {
        text-decoration: none !important;
      }

      span,
      img {
        transform: translateY(-3px);
      }
    }

    &.menu-search {
      display: none;

      @include breakpoint("sm") {
        display: flex;
      }

      @include breakpoint("md") {
        display: none;
      }
    }
  }
}
