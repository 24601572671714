@import "../_config/index";

.title {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px color(grayPrimary02);
  text-transform: uppercase;
  font-family: text-family(font-soins-sans);
  font-size: 28px;
  font-weight: text-weight(bold);
  color: color(blackColor);
  text-align: center;

  @include breakpoint("sm") {
    margin-bottom: 30px;
  }

  @include breakpoint("lg") {
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
}
