$breakpoints: (
  "xs": 375px,
  "ss": 524px,
  "sm": 768px,
  "md": 992px,
  "lg": 1366px,
  "xl": 1800px,
);

$colors: (
  orangePrimary: #ff3300,
  greenPrimary: #1fba9b,
  grayPrimary: #424242,
  grayPrimary02: #636262,
  grayPrimary03: #969696,
  grayPrimary04: #dbdbdb,
  grayPrimary05: #ececec,
  whiteColor: #fff,
  iceColor: #f8f8f8,
  blackColor: #000,
  notificationError: #d81d34,
  notificationSuccess: #1fba9b,
  notificationWarming: #ffc107,
  notificationInfo: #17a2b8,
);

$spaces: (
  xxxs: 4px,
  xxs: 8px,
  xs: 12px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 48px,
  xxl: 72px,
  xxxl: 96px,
  xxxxl: 120px,
);

$textFamily: (
  font-soins-sans: (
    "SoinSans",
    sans-serif,
  ),
  font-soins-sans-roman: (
    "SoinSans-Roman",
    sans-serif,
  ),
  font-soins-sans-oblique: (
    "SoinSans-Oblique",
    sans-serif,
  ),
);

$textSizes: (
  xxl: 32px,
  xl: 28px,
  l: 24px,
  m: 22px,
  s: 20px,
  xs: 16px,
  xxs: 14px,
  xxxs: 8px,
);

$textWeights: (
  light: 100,
  regular: 300,
  normal: normal,
  bold: bold,
);

$transitions: (
  default: all 0.2s ease-in-out,
  fast: all 0.1s ease-in-out,
);
