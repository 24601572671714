@import "../_config/index";

.page-store {
  margin-top: 30px;
}

.stores-big-banner {
  display: none;
  margin-bottom: 20px;

  @include breakpoint("xs") {
    display: block;
  }
}

.store-logo {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 0 0 30px;
  border: solid 1px color(grayPrimary04);
  border-radius: 10px;

  img {
    max-width: 184px;
    max-height: 184px;
    display: block;
  }
}
