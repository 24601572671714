@import "../_config/index";

.page-category {
  padding-top: 60px;
}

.shop-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;

  @include breakpoint("sm") {
    flex-direction: row;
  }

  .shop-area-sidebar {
    width: 300px;
    display: none;
    background-color: color(whiteColor);
    box-shadow: 0 5px 45px color(grayPrimary);
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 16;

    @include breakpoint("md") {
      width: $sidebarWidth;
      display: block !important;
      background-color: transparent;
      box-shadow: initial;
      overflow-y: initial;
      overflow-x: initial;
      top: initial;
      bottom: initial;
      left: initial;
      position: initial;
      z-index: initial;
    }
  }

  .shop-area-content {
    display: flex;
    flex-direction: column;
    margin-left: 0px;

    @include breakpoint("md") {
      width: calc(100% - #{$sidebarWidth} - 32px);
      margin-left: 20px;
    }
  }
}

.shop-area-list {
  margin-bottom: 0px;

  &-desktop {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    & > div {
      transition: transition(fast);
    }

    & > .product-box-container {
      width: calc((100% - 15px) / 2);

      @include breakpoint("lg") {
        width: calc((100% - (15px * 2)) / 3);
      }

      .product-box {
        width: 100%;
        height: 100%;
      }
    }

    & > .fx {
      display: flex;
      margin: 0;
      padding: 0;
      float: none;
      flex: initial;

      .product-box {
        width: 100%;
      }

      &.list-view {
        @include breakpoint("sm") {
          width: calc((100% - 15px) / 2);
        }

        .product-box {
          flex-direction: row;

          @include breakpoint("sm") {
            flex-direction: column;
          }

          .product-box-image {
            width: 30%;

            @include breakpoint("sm") {
              width: auto;
            }
          }

          .product-box-info {
            width: 70%;
            align-items: center;
            justify-content: center;

            @include breakpoint("sm") {
              width: auto;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    & > .col-xs-3 {
      width: calc((100% - (15px * 3)) / 4);
    }

    & > .col-xs-4 {
      width: calc((100% - (15px * 2)) / 3);
    }

    & > .col-xs-6 {
      width: calc((100% - 15px) / 2);
    }
  }
}

.shop-area-sidebar {
  .shop-sidebar-header {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: color(orangePrimary);

    .close-menu {
      height: 50px;
      background: transparent;
      border: none;
      cursor: pointer;

      i {
        font-size: 22px;
        color: color(whiteColor);
      }
    }

    h3 {
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: text-weight(bold);
      color: color(whiteColor);

      i {
        margin-right: 10px;
        font-size: 26px;
      }
    }

    @include breakpoint("md") {
      display: none;
    }
  }

  .shop-sidebar-content {
    margin-bottom: 30px;
    padding: 20px;

    @include breakpoint("md") {
      padding: 0px;
    }

    & > .filter {
      padding: 0;
      margin: 0 0 15px;
      border-bottom: solid 1px #f7f7f7;

      .filter-option {
        box-shadow: none;
        max-height: 32px;
        transition: transition(default);

        & > span {
          height: auto;
          line-height: normal;
          margin: 0 0 15px;
          padding: 0;
          box-shadow: none;
          border-bottom: none;
          display: flex;
          align-items: center;
          font-family: text-family(font-soins-sans);
          font-size: text-size(xs);
          font-weight: text-weight(bold);
          color: color(grayPrimary);
          position: relative;

          &:after {
            top: 50%;
            bottom: auto;
            right: 0;
            padding: 0;
            margin: 0;
            transform: translateY(-50%) rotate(90deg);
            font-family: FontAwesome;
            font-size: 22px;
            content: "\f107";
            color: color(orangePrimary);
            position: absolute;
            transition: transition(fast);
          }
        }

        &--active {
          max-height: 1500px;

          & > span {
            &:after {
              transform: translateY(-50%);
            }
          }
        }

        .filter-values {
          max-height: 255px;
          border: none;
          padding: 0;
          margin: 0 0 15px;
          overflow-y: visible;

          &.filter-size {
            li {
              margin: 3px 1px;
              padding: 0;

              a {
                height: 30px;
                padding: 0 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                border: 1px solid #636262;
                font-family: text-family(font-soins-sans);
                font-size: text-size(xxs);
                font-weight: text-weight(bold);
                color: #636262;
              }
            }
          }

          & > .filter-value {
            & > a {
              display: flex;
              align-items: center;
              font-family: text-family(font-soins-sans-roman);
              font-size: text-size(xxs);
              font-weight: text-weight(regular);
              color: #636262;

              &:before {
                width: 14px;
                height: 14px;
                display: flex;
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0;
                font-family: FontAwesome !important;
                font-size: 14px;
                align-items: center;
                justify-content: center;
                vertical-align: inherit;
                background-clip: inherit;
                transform: translateY(-50%);
                transition: transition(fast);
                opacity: 0;
                content: "\f00c";
                position: absolute;
              }

              &:after {
                width: 14px;
                height: 14px;
                border-radius: 3px;
                border: solid 1px color(grayPrimary);
                order: -1;
                margin-right: 8px;
                content: "";
              }
            }

            &--selected > a,
            & > a:hover {
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.filter-clear-all {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color(whiteColor);
  border: solid 1px color(orangePrimary);
  border-radius: 4px;
  text-decoration: none !important;
  font-family: text-family(font-soins-sans);
  font-size: text-size(xs);
  font-weight: text-weight(regular);
  color: color(orangePrimary);
  transition: transition(fast);

  &:hover {
    background-color: color(orangePrimary);
    color: color(whiteColor) !important;
  }
}

.list-banners-sidebar {
  display: none;

  @include breakpoint("md") {
    display: block;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.button-filter-mobile {
  width: 100%;
  height: 42px;
  padding: 0 16px;
  background: color(grayPrimary05);
  display: flex;
  border: none;
  border-radius: 6px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  font-family: text-family(font-soins-sans);
  font-weight: text-weight(regular);
  font-size: text-size(xxs);
  color: color(grayPrimary02);
  cursor: pointer;

  @include breakpoint("md") {
    display: none;
  }

  &:after {
    display: block;
    font-family: "FontAwesome";
    font-size: 22px;
    content: "\f107";
    color: color(orangePrimary);
  }

  &--mobile {
    @include breakpoint("sm") {
      display: none;
    }
  }

  &--tablet {
    display: none;

    @include breakpoint("sm") {
      display: flex;
    }

    @include breakpoint("md") {
      display: none;
    }
  }
}

.shop-area-list-desktop {
  > .fx, > .product-box-container {
    order: 3;
  }

  .banner-area-2 {
    order: 2;
    width: 100%;
  }

  .col-md-3:nth-child( -n + 5) {
    order: 1;
  }

  .product-box-container:nth-child( -n + 4),
  .col-md-4:nth-child( -n + 4) {
    order: 1;
  }

  .col-md-6:nth-child( -n + 3) {
    order: 1;
  }
}