@import "../_config/index";

.showcases {
  .owl-nav {
    button {
      font-size: 36px;
      height: 30px;
      width: 30px;
      border: 2px solid #222;
      line-height: 19px;
      border-radius: 30px;
      color: #000;

      span {
        display: block;
        height: 30px;
        line-height: 17px;
      }
    }

    .owl-prev {
      left: -20px;
    }

    .owl-next {
      right: -20px;
    }
  }
}