@import "../_config/index";

.form-search {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.show-form {
    display: flex;
  }

  @include breakpoint("sm") {
    display: none;
  }

  @include breakpoint("md") {
    width: 260px;
    height: $headerheight;
    margin-bottom: 0;
    display: flex;
  }

  input {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    display: block;
    border: solid 1px #52606c;
    border-radius: 6px;
    background: #fff;
    font-family: text-family(font-soins-sans);
    font-size: 14px;
    font-weight: textWeight(regular);
    color: #52606c;

    @include placeholder {
      color: #ccc;
    }
  }

  button {
    width: 40px;
    height: 40px;
    display: block;
    border: none;
    background-color: transparent;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;

    img {
      display: block;
    }
  }
}
