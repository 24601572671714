@import "../_config/index";

.product-box-prices {
  width: 100%;
  display: flex;
  justify-content: center;

  &-type {
    width: calc(50% - 1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.inline-prices {
      width: 100%;
      gap: 12px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .product-box-price {
      text-align: center;

      &,
      & a {
        max-width: 150px;
        font-family: text-family(font-soins-sans-roman);
        font-weight: text-weight(regular);
        font-size: 11px;
        color: #969696;
      }
    }
  }

  &-retail {
    .product-tag {
      background: #ff3300;
    }
  }

  &-wholesale {
    .product-tag {
      background: #40b760;
    }
  }

  .price-divider {
    width: 1px;
    height: 100%;
    margin: 0 10px;
    background-color: color(grayPrimary05);

    @include breakpoint("sm") {
      margin: 0 20px;
    }

    @include breakpoint("md") {
      margin: 0 30px;
    }

    @include breakpoint("lg") {
      margin: 0 40px;
    }
  }

  del,
  strong {
    display: block;
    white-space: nowrap;
  }

  del {
    font-size: 12px;
    font-family: text-family(font-soins-sans-roman);
    font-weight: text-weight(regular);
    color: #969696;
  }

  strong {
    font-size: 16px;
    font-family: text-family(font-soins-sans);
    font-weight: text-weight(bold);
    color: #424242;
  }
}

.product-tag {
  max-width: 70px;
  padding: 3px 6px 0 6px;
  margin-bottom: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: text-family(font-soins-sans-roman);
  font-weight: text-weight(regular);
  font-size: 10px;
  color: #fff;

  &-retail {
    background: #ff3300;
  }

  &-wholesale {
    background: #40b760;
  }
}
